<template>
  <div class="selling-point-container layout-padding">
    <PageTitle
      class="my-8"
      :title="content.pressAndMentions"
      :childOne="content.pressAndMentions"
      routeOne="/press"
    />
    <div class="grid grid-cols-6 gap-10 my-8" v-if="loading">
      <div
        class="col-span-6 lg:col-span-2 md:col-span-3"
        v-for="n in 10"
        :key="n"
      >
        <PressCardSkeleton />
      </div>
  </div>
    <div class="grid grid-cols-6 gap-10 my-8" v-else>
      <div
        class="col-span-6 lg:col-span-2 md:col-span-3"
        v-for="item in news.data"
        :key="item.id"
      >
        <PressCard :news="item" />
      </div>
    </div>
  </div>
</template>

<script>
import PressCard from "/src/components/ui/Press/PressCard.vue";
import PressCardSkeleton from "/src/components/ui/Skeletons/PressCardSkeleton.vue";

export default {
  components: { PressCard, PressCardSkeleton },
  inject: ["content"],
  data() {
    return {
      loading: true,
    };
  },
  async mounted() {
    await this.$store.dispatch("press/allNews");
    this.loading = false;
  },
  computed: {
    news() {
      return this.$store.getters["press/allNews"];
    },
  },
};
</script>