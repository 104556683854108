<template>
  <div class="press-card-skeleton">
    <div class="press-card-img"></div>
    <div class="flex items-start mt-6">
      <div class="date-section flex justify-center me-4"></div>
      <div class="text-section w-full">
        <div class="title lora-bold"></div>
        <div class="description mt-4 mb-1"></div>
        <div class="description my-2"></div>
        <div class="description my-2"></div>
      </div>
    </div>
  </div>
</template>
