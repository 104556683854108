<template>
  <div class="press-card">
    <div
      class="press-card-img"
      :style="`background: url(${news.image})`"
    ></div>
    <div class="flex items-start mt-6">
      <div class="date-section flex justify-center me-4">
        <div class="day lora-bold">{{ day }}</div>
        <div class="year">{{ month }} {{ year }}</div>
      </div>
      <div class="text-section">
        <div class="title lora-bold">
          {{ news.title }}
        </div>
        <div class="description">
          <p class="line-clamp-3">
            {{ news.description }}
          </p>
          <span class="press-readmore-btn text-borderColor lora-bold">
            <router-link
              :to="{ name: 'pressDetails', params: { id: news.id } }"
            >
              {{ content.view }}
            </router-link>
          </span>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  inject: ["content"],
  props: ["news"],
  computed: {
    stringLength() {
      let content;
      if (screen.width > 1000) {
        content = Math.round(screen.width / 15);
      } else if (screen.width < 1000 && screen.width > 600) {
        content = Math.round(screen.width / 7);
      } else if (screen.width < 600) {
        content = Math.round(screen.width / 8);
      }
      return content;
    },
    year() {
      return this.news.date.split(",")[0];
    },
    month() {
      return this.news.date.split(",")[1];
    },
    day() {
      return this.news.date.split(",")[2];
    },
  },
};
</script>