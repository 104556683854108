<template>
  <div class="selling-point-container layout-padding">
    <PageTitle
      class="my-8"
      :title="content.pressAndMentions"
      :childOne="content.pressAndMentions"
      routeOne="/press"
    />
    <DetailsSkeleton v-if="loading" />
    <div v-else class="grid grid-cols-2 gap-20 my-8">
      <div class="col-span-2 md:col-span-1">
        <div
          class="press-main-img"
          :style="`background: url(${news?.image})`"
        ></div>
      </div>
      <div class="col-span-2 md:col-span-1">
        <div class="lora-bold press-title">
          {{ news?.title }}
        </div>

        <div class="mt-4 lora-bold text-secondaryColor">
          {{ month }} {{ day }}{{ content.coma }} {{ year }}
        </div>
        <div class="press-description mt-8">
          {{ news?.description }}
        </div>
        <div class="relative mt-8">
          <swiper
            :slides-per-view="3"
            :space-between="20"
            :navigation="navigation"
            :breakpoints="breakpoints"
            :loop="true"
          >
            <swiper-slide v-for="(img, index) in images" :key="index">
              <div class="studio-img" :style="`background: url(${img})`">
                 <div class="view-fullscreen " @click="showImg(index)">
              <div class="border-2 ico-border border-white h-14 w-14 rounded-full flex justify-center items-center opacity-0">
              <img src="/static/images/icons/fullscreen.svg" class="w-8 h-8 " /></div>
            </div>
              </div>
            </swiper-slide>
          </swiper>
          <div class="slide-right-btn" id="nextImg">
            <i class="fas fa-arrow-right text-bluePrimary"></i>
          </div>
          <div class="slide-left-btn" id="prevImg">
            <i class="fas fa-arrow-right text-bluePrimary"></i>
          </div>
          <vue-easy-lightbox
            :visible="visible"
            :imgs="images"
            :index="index"
            @hide="handleHide"
          ></vue-easy-lightbox>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Swiper, SwiperSlide } from "swiper/vue";
import SwiperCore, { Navigation, Pagination } from "swiper";
import DetailsSkeleton from "../../../components/ui/Skeletons/DetailsSkeleton.vue";

import "swiper/swiper-bundle.css";
import "swiper/components/navigation/navigation.min.css";

SwiperCore.use([Navigation, Pagination]);

export default {
  components: { Swiper, SwiperSlide, DetailsSkeleton },
  inject: ["content"],
  props: ["id"],
  data() {
    return {
      loading: true,
      visible: false,
      index: 0,
      navigation: {
        nextEl: "#nextImg",
        prevEl: "#prevImg",
      },
      breakpoints: {
        // when window width is >= 320px
        0: {
          slidesPerView: 1,
          spaceBetween: 20,
        },
        // when window width is >= 480px
        567: {
          slidesPerView: 2,
          spaceBetween: 20,
        },
        // when window width is >= 640px
        992: {
          slidesPerView: 2,
          spaceBetween: 20,
        },
        1200: {
          slidesPerView: 3,
          spaceBetween: 20,
        },
        1500: {
          slidesPerView: 3,
          spaceBetween: 20,
        },
        1800: {
          slidesPerView: 3,
          spaceBetween: 20,
        },
      },
    };
  },
  async mounted() {
    await this.$store.dispatch("press/news", this.id);
    this.loading = false;
  },
  computed: {
    news() {
      return this.$store.getters["press/news"];
    },
    year() {
      return this.news.date.split(",")[0];
    },
    month() {
      return this.news.date.split(",")[1];
    },
    day() {
      return this.news.date.split(",")[2];
    },
    images() {
      let images = [];

      if (this.news?.image) images.push(this.news?.image);

      if (this.news?.images) {
        for (let i = 0; i < this.news?.images?.length; i++) {
          images.push(this.news?.images[i].url);
        }
      }

      return images;
    },
  },
  methods: {
    showImg(index) {
      this.index = index;
      this.visible = true;
    },
    handleHide() {
      this.visible = false;
    },
  },
};
</script>